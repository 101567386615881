export default [
  {
    name: "login",
    path: "/login/:key?",
    component: () => import(/* webpackChunkName: "login", webpackPreload: true */ "@/pages/auth/Login"),
    meta: {}
  },
  {
    name: "mfa",
    path: "/mfa",
    component: () => import(/* webpackChunkName: "login", webpackPreload: true */ "@/pages/auth/MFA"),
    meta: {}
  },
  {
    name: "reset-password",
    path: "/reset-password/:key?",
    component: () => import(/* webpackChunkName: "reset-password", webpackPreload: true */ "@/pages/auth/ResetPassword"),
    meta: {}
  },
  {
    name: "update-password",
    path: "/update-password/:key?",
    component: () => import(/* webpackChunkName: "update-password", webpackPreload: true */ "@/pages/auth/UpdatePassword"),
    meta: {}
  },
];
