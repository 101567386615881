import Vue from "vue";
import Vuetify from "vuetify/lib";
import DatetimePicker from 'vuetify-datetime-picker';
import '@mdi/font/css/materialdesignicons.css';

const opts = {
  theme: {
    themes: {
      light: {
        primary: '#424242',
        secondary: '#a9ddd6',
        accent: '#8c9eff',
        error: '#b71c1c',
        success: '#0ba869',
        admin: '#B3E5FC'
      },
    },
    icons: {
      iconfont: 'mdi',
    },
  }
};

const vuetify = new Vuetify(opts);

Vue.use(Vuetify);
Vue.use(DatetimePicker);

export default vuetify;
