import Vue from "vue";
import Router from "vue-router";
import Middleware, { middleware } from "vue-router-middleware";
import NotFound from "@/pages/static/NotFound";
import { isAuthenticated, isTermsAccepted } from '@/helpers/functions';

//Routes
import auth from "@/router/auth";
import clients from "@/router/clients";
import properties from "@/router/properties";
import reservations from "@/router/reservations";
import reports from "@/router/reports";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    ...middleware("guest", [
      ...auth
    ]),
    ...middleware("auth", [
      ...clients,
      ...properties,
      ...reservations,
      ...reports,
    ]),
    {
      path: "*",
      component: NotFound
    }
  ]
});

Vue.use(Middleware, {
  router,
  middlewares: {
    guest(params, to, from, next) {
      if (isAuthenticated()) {
        next({ name: "revenue-share" });
      } else {
        next();
      }
    },
    auth(params, to, from, next) {
      if (isAuthenticated()) {
        next();
      } else {
        next({ name: "login" });
      }
    }
  }
});

export default router;
