import {
  range,
  money,
  percent,
  variation,
  absolute,
  isAuthenticated,
  redirectTo,
  getCurrentUrl,
  isEmpty,
  getKeyByValue,
  findByAttribute,
  findIndexByAttribute,
  whereInByAttribute,
  getTimezone,
  getLocale,
  phoneFormat,
  ip,
  localize,
  isDateBetween,
  debounce,
  exportXLSX,
  decrypt,
  encrypt,
} from '@/helpers/functions';

import moment from 'moment-timezone';
import beConfig from '@/config/be';

export default {
  methods: {
    /**
     *
     * @param href
     */
    $redirectTo(href) {
      redirectTo(href);
    },

    /**
     *
     * @returns {string}
     */
    $getCurrentUrl() {
      return getCurrentUrl();
    },

    /**
     *
     * @param route
     */
    $toRoute(route) {
      if (this.$route.name === route.name &&
        JSON.stringify(this.$route.params) === JSON.stringify(route.params)) {
        return false;
      }

      this.$router.push(route);
    },

    /**
     *
     * @param obj
     * @returns {boolean}
     */
    $isEmpty(obj) {
      return isEmpty(obj);
    },

    /**
     *
     * @param object
     * @param value
     * @returns {string | undefined}
     */
    $getKeyByValue(object, value) {
      return getKeyByValue(object, value);
    },

    /**
     *
     * @param array
     * @param attribute
     * @param value
     */
    $findByAttribute(array, attribute, value) {
      return findByAttribute(array, attribute, value);
    },

    /**
     *
     * @param array
     * @param attribute
     * @param value
     */
    $findIndexByAttribute(array, attribute, value) {
      return findIndexByAttribute(array, attribute, value);
    },

    /**
     *
     * @param array
     * @param attribute
     * @param value
     */
    $whereInByAttribute(array, attribute, value) {
      return whereInByAttribute(array, attribute, value);
    },

    /**
     * @returns {boolean}
     */
    $isAuthenticated() {
      return isAuthenticated();
    },

    /**
     * @param count
     * @returns {Array}
     */
    $range(count) {
      return range(count);
    },

    /**
     * @param number
     * @returns {*}
     */
    $absolute(number) {
      return absolute(number);
    },

    /**
     * @param amount
     * @param currency
     * @param options
     * @returns {*}
     */
    $money(amount, currency, options, digits) {
      if (!options) {
        options = this.$locale();
      }

      return money(amount, currency, options, digits);
    },

    /**
     * @param {*} amount
     * @param {*} format
     * @param {*} options
     * @returns {*}
     */
    $percent(amount, format, options) {
      if (!format) {
        format = this.$locale();
      }

      return percent(amount / 100, format, options);
    },

    /**
     * @param {*} amount
     * @param {*} format
     * @param {*} options
     * @returns {*}
     */
    $variation(amount, format, options) {
      if (!format) {
        format = this.$locale();
      }

      return variation(amount, format, options);
    },

    /**
     * @param phone
     * @returns {*}
     */
    $phoneFormat(phone) {
      return phoneFormat(phone);
    },

    /**
     * @returns {*}
     */
    $locale() {
      return getLocale();
    },

    /**
     * @returns {*}
     */
    $timezone() {
      return getTimezone();
    },

    /**
     * @param callback
     * @returns {*}
     */
    $ip(callback) {
      return ip(callback);
    },

    /**
     * @param {*} data
     */
    $localize(data, locale, fallback) {
      if (!locale) {
        locale = this.$locale();
      }
      return localize(data, locale, fallback);
    },

    /**
     * 
     * @param {*} path 
     * @returns 
     */
    $customerPortalUrl(path) {
      return `https://${beConfig.customerPortalUri}/${path}`;
    },

    /**
     * 
     * @param {*} date 
     * @param {*} options 
     * @returns 
     */
    $isDateBetween(date, options = {}) {
      return isDateBetween(date, options);
    },

    /**
     * 
     * @param {*} birthday 
     * @param {*} options 
     * @returns 
     */
     $isBirthdayBetween(birthday, options = {}) {
      birthday = moment(birthday).set('year', moment().year());

      options = {
        start_date: moment(options.start_date).set('year', moment().year()),
        end_date: moment(options.end_date).set('year', moment().year()),
      }

      return isDateBetween(birthday, options);
    },

    /**
     * 
     * @param {*} func 
     * @param {*} timeout 
     * @returns 
     */
    $debounce(func, wait = 300) {
      return debounce(func, wait);
    },

    /**
     * @param name
     * @param data
     * @returns {*}
     */
    $exportXLSX(name, data) {
      return exportXLSX(name, data);
    },

    /**
     * @param encryption
     * @param secret
     * @returns {*}
     */
    $encrypt(encryption, secret) {
      return encrypt(encryption, secret);
    },

    /**
     * @param encryption
     * @param secret
     * @returns {*}
     */
    $decrypt(encryption, secret) {
      return decrypt(encryption, secret);
    },
  }
};
