import { mapState } from "vuex";

import { LOADING_SHOW, LOADING_HIDE } from "@/store/app/actions";

export default {
  methods: {
    showAppLoading() {
      this.$store.dispatch(LOADING_SHOW);
    },
    hideAppLoading() {
      this.$store.dispatch(LOADING_HIDE);
    }
  },
  computed: {
    ...mapState({
      appLoading: state => state.app.appLoading
    })
  }
};
