export default [
  {
    name: "revenue-share",
    path: "/",
    component: () => import("@/pages/reports/RevenueShares"),
    meta: {
      title: 'titles.revenue_shares_report',
    }
  },
];
