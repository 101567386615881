export default [
  {
    name: "properties",
    path: "/properties",
    component: () => import(/*webpackChunkName: "properties" */ "@/pages/properties/Index"),
    meta: {
      breadcrumbs: [
      ],
      title: 'titles.properties'
    }
  },
  {
    name: "property-costs",
    path: "/properties/:id/costs",
    component: () => import(/*webpackChunkName: "properties" */ "@/pages/property-costs/Index"),
    meta: {
      breadcrumbs: [
        { text: 'titles.properties', to: 'properties', params: true },
        { text: 'titles.property_settings', to: 'property-settings' },
        { text: 'titles.property_cost_settings', to: 'property-cost-settings' },
      ],
      title: 'titles.property_costs'
    }
  },
  {
    name: "property-revenue-share",
    path: "/properties/:id/revenue-shares",
    component: () => import(/*webpackChunkName: "properties" */ "@/pages/property-revenue-shares/Index"),
    meta: {
      breadcrumbs: [
        { text: 'titles.properties', to: 'properties', params: true },
        { text: 'titles.property_settings', to: 'property-settings' },
      ],
      title: 'titles.property_revenue_share'
    }
  },
  {
    name: "property-contracts",
    path: "/properties/:id/property-contracts",
    component: () => import(/*webpackChunkName: "properties" */ "@/pages/property-contracts/Index"),
    meta: {
      breadcrumbs: [
        { text: 'titles.properties', to: 'properties', params: true },
        { text: 'titles.property_settings', to: 'property-settings' },
      ],
      title: 'titles.property_contracts'
    }
  }
];
