export default [
  {
    name: "reservations",
    path: "/reservations",
    component: () => import(/*webpackChunkName: "reservations" */ "@/pages/reservations/Index"),
    meta: {
      title: 'titles.reservations',
      loginRedirect: true
    }
  },
  {
    name: "calendar",
    path: "/calendar",
    component: () => import(/*webpackChunkName: "reservations" */ "@/pages/calendar/Index"),
    meta: {
      title: 'titles.calendar',
      loginRedirect: true
    }
  },
  {
    name: "annual-calendar",
    path: "/annual-calendar",
    component: () => import(/*webpackChunkName: "reservations" */ "@/pages/annual-calendar/Index"),
    meta: {
      title: 'titles.calendar',
      loginRedirect: true
    }
  }
];
